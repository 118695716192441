@import './styles/theme-overrides';
@import './styles/base-styles';
@import './styles/helpers';
@import 'include-media';

// Global variables
:root {
  // --font-family: 'Roboto', 'Segoe UI', 'Helvetica', sans-serif;
  --font-family: 'Inter', 'Segoe UI', 'Helvetica', sans-serif;

  --color-primary: #3f51b5;
  --color-primary-hover: #6b7cd2;
  --color-primary-active: #2e3d9b;
  --color-primary-focus: var(--color-primary-active);
  --color-accent: #ffab40;
  --color-warn: #f44336;
  --color-info: #50a5f1;
  --color-success: #54b676;
  --color-border: rgba(0, 0, 0, 0.2);

  --color-primary-text: white;
  --color-text: hsl(0, 0%, 10%);
  --color-text-fade: hsl(0, 0%, 60%);
  --color-icon-input: #5c5c5c;

  --color-background: white;
  --color-background-2: hsl(0, 0%, 97%);
  --color-background-2-hover: hsl(0, 0%, 90%);
  --color-background-accent: #222b45;
  --color-background-accent-hover: #373f58;

  --shadow: 0px 1px 15px 1px rgba(65, 65, 65, 0.1);
  --shadow-menu: 0px 4px 20px 1px rgba(65, 65, 65, 0.15), 0px 4px 8px -3px rgba(65, 65, 65, 0.3);
  --border-radius: 4px;
  --transition: all 150ms ease;
  --transition-slow: all 300ms ease;

  --height-input: 42px;
  --height-toolbar: 64px;
}

.card {
  padding: 1.5rem;
  box-shadow: var(--shadow);
  // border: 2px solid var(--color-background-2);
  border-radius: 8px;

  @include media('<tablet') {
    padding: 0.5rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;

  p.prefix,
  p.suffix {
    font-weight: 600;
  }

  .prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    color: var(--color-icon-input);
    margin: 0;

    & ~ input {
      padding-left: 48px;
    }
  }

  .suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    color: var(--color-icon-input);
    margin: 0;

    & ~ input {
      padding-right: 48px;
    }

    &.clear-icon {
      font-size: 15px;
      cursor: pointer;
      transition: var(--transition);

      &:hover {
        color: var(--color-warn);
      }
    }
  }

  button.suffix {
    height: 32px;
    width: 32px;
    line-height: 32px;

    i {
      font-size: 20px;
    }
  }

  label {
    margin-bottom: 0.3rem;
    font-size: 12px;
    font-weight: 600;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;

  @include media('<tablet') {
    justify-content: flex-start;
    flex-wrap: wrap;

    .header-tools {
      margin-top: 0.7rem;
      width: 100%;
    }
  }

  app-back {
    margin-right: 1rem;
  }

  h1 {
    margin: 0;
    flex-grow: 1;
  }

  .header-tools {
    display: flex;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.table-wrapper {
  width: 100%;

  .table-selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: var(--color-primary-active);
    color: var(--color-primary-text);
    border-radius: 8px 8px 0 0;
    height: 56px;
    max-height: 0;
    overflow: hidden;
    transition: var(--transition-slow);

    .actions {
      > *:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    &.visible {
      max-height: 56px;

      & + .table-container .mat-table thead th {
        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }
  }

  .table-container {
    width: 100%;
    overflow: auto;
    min-height: 100px;
  }
}

.snackbar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  min-width: 89px;
  min-height: 36px;
  width: auto;

  .mat-simple-snackbar-action {
    button {
      height: 40px;
      width: 40px;
      padding: 0;

      .mat-button-wrapper {
        color: white;
        font-size: 18px;
      }
    }
  }
}

.success-snackbar {
  background-color: var(--color-success);
  color: white;
}

.error-snackbar {
  background-color: var(--color-warn);
  color: white;
}
